<template>
  <div>
    <div class="my-bg">
      <b-container>
        <b-row class="d-flex align-items-center justify-content-center">
          <b-col md="12" sm="12" class="py-3 py-xs-0">
            <div>
              <div class="pt-3">
                <h1 class="font-weight-bolder text-colorBlue">
                  World Class Medical Tourism Platform
                </h1>
              </div>
              <div>
                <h4 class="font-weight-normal text-colorGreen">
                  Most Extensive & Affordable Medical Procedures
                </h4>
              </div>

              <div>
                <h5 class="font-weight-bolder text-colorGreen pb-5">
                  Contact Us Now By Phone Number or Email Address
                </h5>
              </div>
            </div>
            <div class="d-flex justify-content-start">
              <b-button
                pill
                variant="colorGreen"
                size="md"
                class="first-button text-light font-weight-bolder mr-2 mb-1 mb-xs-0"
                @click="contactPage"
              >
                <img
                  src="@/assets/images/icons/button2.svg"
                  class="logo mr-50"
                  alt="image"
                />
                Back to Home
              </b-button>
              <b-button
                pill
                variant="colorGreen"
                size="md"
                class="first-button text-light font-weight-bolder mr-2 mb-1 mb-xs-0"
                @click="getQuote"
              >
                <img
                  src="@/assets/images/icons/button1.svg"
                  class="mr-50"
                  alt="image"
                />Get a Quote
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hoveredCol1: false,
      hoveredCol2: false,
      hoveredCol3: false,
      hoveredCol4: false,
    };
  },
  methods: {
    contactPage() {
      this.$router.push({ name: "Home" });
    },
    getQuote() {
      this.$router.push({ path: "/", hash: "#getQuote" });
    },
  },
};
</script>

<style scoped>
.my-bg {
  background: url("~@/assets/images/contactUs/banner.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.button-container .first-button:hover .logo {
  content: url("~@/assets/images/icons/medical-cross-stroke.svg");
}
.hover-img {
  display: none;
}
.image-container-hover:hover .hover-img {
  display: block;
  margin: 0 auto;
}

.image-container-hover:hover .base-img {
  display: none;
}
</style>
